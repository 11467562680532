export default build =>
    build.query({
        tagTypes: ['allStudents'],
        keepUnusedDataFor: 1,
        query(payload) {
            console.log("payload in get all students",payload)            
            return {
                url: `/enroll/getAllStudents?page_number=${payload.page_number}&page_size=${payload.page_size}&purchase_status=${payload.purchase_status}&course_id=${payload.course_id}&coupon_code=${payload.coupon_code}`,
              //  url: `/product/getAllProducts`,
                method: 'GET'              
            };
           
        },
       providesTags: ['allStudents']
    })
