export default build =>
    build.mutation({
        query(payload) {
            console.log("payload",payload)
            return {
                url: '/course/createFreeYoutubeVideo',
                method: 'POST',
                body: payload,
            };
        },
        invalidatesTags: ['allFreeVideos'],
       
    })
