export default build =>
    build.mutation({
        query(payload) {
            console.log("payload",payload)
            return {
                url: '/course/updateSectionName',
                method: 'POST',
                body: payload,
            };
        },
        invalidatesTags: ['courseById'],
       
    })
