import { api } from '../../api'
import createCourse from './createCourse'
import createCourseSection from './createCourseSection'
import createLessonName from './createLessonName'
import deleteSection from './deleteSection'
import deleteLesson from './deleteLesson'

import getAllCourses from './getAllCourses'
import getCourseById from './getCourseById'
import updateLessonName from './updateLessonName'
import updateSectionName from './updateSectionName'
import createCourseThumbnailImage from './createCourseThumbnailImage'
import deleteCourse from './deleteCourse'
import createCouponCode from './createCouponCode'
import getCouponCodes from './getCouponCodes'
import deleteCoupon from './deleteCoupon'
import editCoupon from './editCoupon'
import editCourse from './editCourse'
import CreateResource from './CreateResource'
import UpdateResource from './UpdateResource'
import DeleteResource from './DeleteResource'
import getResources from './getResources'
import createFreeVideoLink from './createFreeVideoLink'
import updateFreeVideoLink from './updateFreeVideoLink'
import deleteFreeVideoLink from './deleteFreeVideoLink'
import getAllFreeVideos from './getAllFreeVideos'
import getCouponCodeByCourse from './getCouponCodeByCourse'


export const courseApi = api.injectEndpoints({
    endpoints: build => ({
        CreateCourse: createCourse(build),
        EditCourse:editCourse(build),
        CreateCouponCode:createCouponCode(build),
        GetCouponCodes:getCouponCodes(build),
        GetAllCourses:getAllCourses(build),
        GetCourseById:getCourseById(build),
        CreateCourseSection:createCourseSection(build),
        CreateLessonName:createLessonName(build),
        UpdateLessonName:updateLessonName(build),      
        UpdateSectionName:updateSectionName(build),
        DeleteSection:deleteSection(build),
        DeleteLesson:deleteLesson(build),
        DeleteCourse:deleteCourse(build),
        DeleteCoupon:deleteCoupon(build),
        EditCoupon:editCoupon(build),
        CreateCourseThumbnailImage:createCourseThumbnailImage(build),
       CreateResource:CreateResource(build),
       UpdateResource:UpdateResource(build),
       DeleteResource:DeleteResource(build),
       GetResources:getResources(build),

       CreateFreeVideoLink:createFreeVideoLink(build),
       UpdateFreeVideoLink:updateFreeVideoLink(build),
       DeleteFreeVideoLink:deleteFreeVideoLink(build),
       GetAllFreeVideos:getAllFreeVideos(build),
       GetCouponCodeByCourse:getCouponCodeByCourse(build)



    }),
    overrideExisting: true,
})
export const {
     useCreateCourseMutation,
     useGetAllCoursesQuery,
     useGetCourseByIdQuery,
     useCreateCourseSectionMutation,
     useCreateLessonNameMutation,
     useUpdateLessonNameMutation,
     useUpdateSectionNameMutation,
     useDeleteSectionMutation,
     useDeleteLessonMutation,
     useDeleteCourseMutation,
     useCreateCourseThumbnailImageMutation,
     useCreateCouponCodeMutation,
     useDeleteCouponMutation,
     useEditCouponMutation,
     useGetCouponCodesQuery,
     useEditCourseMutation,

     useCreateResourceMutation,
     useUpdateResourceMutation,
     useDeleteResourceMutation,
     useGetResourcesQuery,

     useCreateFreeVideoLinkMutation,
     useUpdateFreeVideoLinkMutation,
     useDeleteFreeVideoLinkMutation,
     useGetAllFreeVideosQuery,
    useGetCouponCodeByCourseMutation
     



    } = courseApi
