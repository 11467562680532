export default build =>
    build.mutation({
        query(payload) {
            console.log("in delete student",payload)
            return {
                url: `/enroll/deleteUser`,
                method: 'POST',
                body: payload,
            };
        },
        invalidatesTags: ['allStudents'],

       
    })
