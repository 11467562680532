import React from 'react'
import Template from '../Components/Template'

function Home() {
    const HomeScreen=()=>{
      return(
        <>
        <main id="main" class="main">
        <div className='container'>
         
         <div className='row'>
         <div className="col-md-6">
                   <div className="card">
                    Into dashboard
                    </div>
                    </div>
                    </div>
                    </div>
                    </main>
        </>
      )
       
    }
  return (
    <Template>
        {HomeScreen()}
    </Template>
  )
}

export default Home