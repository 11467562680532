export default build =>
    build.query({
        tagTypes: ['courseById'],
        keepUnusedDataFor: 1,
        query(payload) {
            console.log("in get course by id payload",payload)            
            return {
                url: `/course/getCourseById?course_id=${payload.course_id}`,
                method: 'GET',
                //body: payload,
            };
           
        },
       providesTags: ['courseById']
    })
