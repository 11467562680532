import React, { useEffect, useState } from 'react';
import { useCreateCouponCodeMutation, useCreateResourceMutation, useDeleteCouponMutation, useDeleteResourceMutation, useEditCouponMutation, useGetCouponCodesQuery, useGetResourcesQuery, useUpdateResourceMutation } from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WarningModal from './WarningModal';

function Resources({ course_details }) {
  console.log("coupon code course id", course_details.data[0]._id);
  const [resources, setResources] = useState({
    caption: "",
    drive_link: "",
    course_id: course_details.data[0]._id  ,
    resource_id:""
  });
  const[isSumbmiting,setIsSubmiting]=useState(false)
  const [isFormValid, setIsFormValid] = useState(false);
  const[resourceData,setresourceData]=useState([])
  const[deleteModal,setDeleteModal]=useState(false)
  const[deleteResource,setDeleteResource]=useState(false)
  const[isEdit,setisEdit]=useState(false)
  const[resource_id,setResourceId]=useState("")
  const [CreateResource, { data, isSuccess, isLoading, isError, error }] = useCreateResourceMutation();

  useEffect(() => {
    setIsSubmiting(false)
    clearForm()
    setIsFormValid(false);

    if (isSuccess) {
      if (data.error === false) {
        toast.success(data.message);
      }
      if (data.error === true) {
        toast.error(data.message);
      }
    }
  }, [data]);
  const { 
    data: allResources= [],  
    isLoading:loading=true,
    isSuccess:isSuccessResources,
    isError:isErrorResources
   } = useGetResourcesQuery({course_id:course_details.data[0]._id});
 
   useEffect(() => {    
    if (isSuccessResources) {
        console.log("InSuccess",allResources);
      
        if(allResources.error==true)
        {
          toast.error(allResources.message)          
          return 
        }
        else
        {
          setresourceData(allResources.data)
           
        }
     
    }
    if (isError) {
        alert("In error")
        console.log(error);
    }
}, [allResources]);
const [DeleteResource, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteLoading, isError:deleteError }] = useDeleteResourceMutation({course_id:course_details.data[0]._id,resource_id:resource_id});
const [UpdateResource, { data:editData, isSuccess:editSuccess, isLoading:editLoading, isError:editError }] = useUpdateResourceMutation({course_id:course_details.data[0]._id,resource_id:resource_id});
  useEffect(()=>{
    setResourceId("")
    setDeleteModal(false)
    setDeleteResource(false)
    if(deleteSuccess)
    {
      if(deleteData.error==false)
      {
        toast.success(deleteData.message)

      }
      if(deleteData.error==true)
      {
        toast.error(deleteData.message)

      }
    }
  },[deleteData])
  useEffect(()=>{
    setResourceId("")
    setisEdit(false)
    setIsSubmiting(false)
    clearForm()
    if(editSuccess)
    {
      if(editData.error==false)
      {
        toast.success(editData.message)

      }
      if(editData.error==true)
      {
        toast.error(editData.message)

      }
    }
  },[editData])
  useEffect(()=>{
    if(deleteResource)
    {
        DeleteResource({
        course_id:course_details.data[0]._id,
        resource_id:resource_id
      })
    }
   },[deleteResource])
  
  const validateForm = () => {
    const { caption, drive_link } = resources;
    if (caption !== "" && drive_link!=="") {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setResources({
      ...resources,
      [e.target.name]: value,
    });
    validateForm();
  };

  const onSubmit = () => {
    setIsSubmiting(true)
    if(isEdit)
    {
      UpdateResource(resources)
    }
    else
    {
      CreateResource(resources);

    }
  };
  const clearForm=()=>{       
    setResources({
      ...resources,
      caption:"",
    drive_link:"",   
      
    })
  }
  const deleteResourceConfirmAlert=(record)=>{   
    setDeleteModal(true)   
    setResourceId(record._id)  
   }
   const editResource=(record)=>{
    console.log("Edit record",record)
    setisEdit(true)
   // setCouponCode(record._id)
    setResources({
      ...resources,
      caption:record.caption,
    drive_link:record.drive_link,
  
    resource_id:record._id,
    course_id:course_details.data[0]._id,
      
    })
setIsFormValid(true)
   }
  
  return (
    <>
      <ToastContainer />
     {
      deleteModal?
      <WarningModal
      setShowWarningModal={setDeleteModal}
      modalTitle={"Delete Lesson"}
      setIsDelete={setDeleteResource}
      showModal={deleteModal}
      />:""
      
    }
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Create Resources</h5>
            <div className="form-group">
              <label htmlFor="input1">Caption</label>
              <input
                type="text"
                className="form-control mb-3"
                id="input1"
                name="caption"
                value={resources.caption}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="input2">Drive Link</label>
              <input
                type="text"
                className="form-control mb-3"
                id="input2"
                name="drive_link"
                value={resources.drive_link}
                onChange={handleChange}              
              />
            </div>
         
         
          
             <button
                  type="submit"
                  className="btn btn-primary btn-block mt-3"
                  onClick={() => onSubmit()}
                  disabled={!isFormValid || isLoading || isSumbmiting} 
                >
                  {isLoading || isSumbmiting ? 
                  'Submitting...' : 
                  isEdit?'Update':
                  'Submit'} 
                </button>
          </div>
        </div>
      </div>
      <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Table</h5>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Actions</th>
                    <th scope="col">Caption</th>                  

                  </tr>
                </thead>
                <tbody>
               
                  {resourceData.map((item, index) => (
                      <tr key={index}>
                         <td>
                         
                         <a 
                         onClick={()=>deleteResourceConfirmAlert(item)} 
                         style={{cursor:'pointer',color:'red'}}>
                         <i class="fa fa-trash" aria-hidden="true"/>
                         </a>
                         <a
                          style={{cursor:'pointer'}}
                          onClick={()=>editResource(item)}
                          >
                         <i class="fa fa-edit" 
                         aria-hidden="true" 
                         style={{marginLeft:"10px"}}></i>

                         </a>

                         </td>
                        <td>
                        <a 
                        href={item.drive_link} target='_blank'
                         style={{cursor:'pointer',color:'red'}}>
                        {item.caption}

                         </a>
                        
                         
                          </td>
                     
                      </tr>
                    ))}
                  
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </>
  );
}

export default Resources;