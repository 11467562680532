import React, { useEffect, useState } from 'react'
import Template from '../Components/Template'
import { useCreateCourseMutation, useDeleteCourseMutation, useEditCouponMutation, useEditCourseMutation, useGetAllCoursesQuery } from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateCourseData } from '../Store/CourseData';
import WarningModal from '../Components/WarningModal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

function Courses() {
const navigate=useNavigate()
const dispatch=useDispatch()
    const[courseBasics,setCourseBasics]=useState({
        course_name:"",
        original_price:"",
        discounted_price:"",
        description:"",
        course_id:""
    })
    const[courseData,setCourseData]=useState([])
    const[isCourseDelete,setIsCourseDelete]=useState(false)
    const[showModelDeleteCourse,setShowModalDeleteCourse]=useState(false)
    const[isSumbmiting,setIsSubmiting]=useState(false)

    
    const[courseId,setCourseId]=useState("")
    const[isEdit,setIsEdit]=useState(false)
    const [isFormValid, setIsFormValid] = useState(false);
    const { 
        data: allCourses= [],  
        isLoading:loading=true,
        isSuccess:isSuccessCourses,
        isError:isErrorCourses
       } = useGetAllCoursesQuery();
      
    const [CreateCourse, { data, isSuccess, isLoading, isError, error }] = useCreateCourseMutation();
    const [DeleteCourse, { data:courseDataDelete, isSuccess:isCourseSuccessDelete, isLoading:isCourseLoadingDelete, isError:isCourseErrorDelete}] = useDeleteCourseMutation();
    const [EditCourse, { data:editData, isSuccess:editSuccess, isLoading:editLoading, isError:editError }] = useEditCourseMutation({course_id:courseId});

console.log("allCourses",allCourses)
    useEffect(() => {
        validateForm();
      }, [courseBasics]);
      useEffect(() => {
        if(isCourseDelete)
        {
          DeleteCourse({
            course_id:courseId
          })
        }
      
      }, [isCourseDelete]);
      useEffect(() => {
       if(isCourseSuccessDelete)
       {
        toast.success("Course deleted successfully")
        setShowModalDeleteCourse(false)
        setCourseId("")
        setIsCourseDelete(false)
       }
      }, [courseDataDelete]);
      const validateForm = () => {
        const { course_name, original_price,discounted_price } = courseBasics;
        if (course_name !== '' && original_price !== ''&& discounted_price !== '') {
          setIsFormValid(true);
        } else {
          setIsFormValid(false);
        }
      };  
    function handleChange(e) {
        const value = e.target.value;
  
        setCourseBasics({
          ...courseBasics,
          [e.target.name]: value
        });
        validateForm()
      }
      useEffect(() => {    
        setIsSubmiting(false)

        if (isSuccess) {
            console.log("InSuccess",data);
          
            if(data.error==true)
            {
              toast.error(data.message)
              clearForm()
               
            }
            else
            {
              clearForm()

                toast.success("Submited")
            }
         
        }
        if (isError) {
            alert("In error")
            console.log(error);
        }
    }, [isLoading]);
    useEffect(() => {    
      setIsSubmiting(false)
      if (editSuccess) {
          console.log("InSuccess",editData);
        
          if(editData.error==true)
          {
            toast.error(editData.message)
            clearForm()
            return 
          }
          else
          {
              toast.success(editData.message)
              clearForm()
          }
       
      }
      if (editError) {
          alert("In error")
          console.log(error);
      }
  }, [editData]);
    useEffect(() => {    
      if (isSuccessCourses) {
          console.log("InSuccess",data);
        
          if(allCourses.error==true)
          {
            toast.error(data.message)          
            return 
          }
          else
          {
            setCourseData(allCourses.data)
             
          }
       
      }
      if (isError) {
          alert("In error")
          console.log(error);
      }
  }, [allCourses]);
    const clearForm=()=>{
      // alert("In clear form")
        setCourseBasics({
          ...courseBasics,
          course_name:"",
        original_price:"",
        discounted_price:""   ,
       // description:"",
        course_id:""
          
        })
      }
      const deleteCourseConfirmAlert=(record)=>{
        setCourseId(record._id)
        setShowModalDeleteCourse(true)
         
       }
       const editCourse=(record)=>{
        setCourseId(record._id)
        setCourseBasics({
          ...courseBasics,
          course_name:record.course_name,
        original_price:record.original_price,
        discounted_price:record.discounted_price,
        description:record.description,
        course_id:record._id
          
        })
        setIsEdit(true)

       }
    const coursesScreen=()=>{
        function onSubmit (){    
          setIsSubmiting(true)
          if(isEdit)
          {
            EditCourse(courseBasics)
          }
          else
          {
            CreateCourse(courseBasics)        

          }
         }
         const navigateToCourseDetails=(courseDetails)=>{
          dispatch(updateCourseData({data:courseDetails}))
          navigate({
            pathname: '/coursecontent',
            // search: `?id=${courseDetails._id}`,    
          });
    
         }
        return(
<>
<main id="main" class="main">
    <ToastContainer/>
    {
      showModelDeleteCourse?
      <WarningModal
      setShowWarningModal={setShowModalDeleteCourse}
      modalTitle={"Delete Lesson"}
      setIsDelete={setIsCourseDelete}
      showModal={showModelDeleteCourse}
      />:""
      
    }
           <div className='container'>
         
<div className='row'>
<div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Course basic details</h5>
             
                <div className="form-group">
                  <label htmlFor="input1">Course Name</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input1"
                  name="course_name"
                  value={courseBasics.course_name}
                  onChange={handleChange}
                   />
                </div>
                <div className="form-group">
                  <label htmlFor="input2">Original Price</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input2"
                  name="original_price"
                  value={courseBasics.original_price}
                  onChange={handleChange}
                   />
                </div>
                <div className="form-group">
                  <label htmlFor="input3">Descounted Price</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="input3" 
                  name="discounted_price"
                  value={courseBasics.discounted_price }
                  onChange={handleChange}
                  />
                </div>   
                <div className="form-group">
                  <label htmlFor="input3">Description</label>
                  <ReactQuill
    className="mb-3"
    value={courseBasics.description}
    onChange={(value) => setCourseBasics({ ...courseBasics, description: value })}
  />
                  {/* <input type="text" 
                  className="form-control mb-3" 
                  id="input3" 
                  name="description"
                  value={courseBasics.description }
                  onChange={handleChange}
                  /> */}
                </div>           
                <button type="submit" 
                className="btn btn-primary"
                disabled={!isFormValid}
                onClick={()=>onSubmit()}
                >
                   {isSumbmiting ? 
                  'Submitting...' : 
                  isEdit?'Update':
                  'Submit'} 
                </button>
             
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Courses</h5>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Actions</th>
                    <th scope="col">Course Name</th>
                    <th scope="col">Price</th>
                    <th scope="col">Descounted Price</th>

                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td>Row 1, Cell 1</td>
                    <td>Row 1, Cell 2</td>
                    <td>Row 1, Cell 3</td>
                  </tr> */}
                  {courseData.map((item, index) => (
                      <tr key={index}>
                         <td>
                         
                         <a onClick={()=>deleteCourseConfirmAlert(item)} 
                         style={{cursor:'pointer',color:'red'}}>

                         <i class="fa fa-trash" aria-hidden="true"/>
                         </a>
                         <a 
                         onClick={()=>editCourse(item)}
                         style={{cursor:'pointer'}}
                         >
                         <i class="fa fa-edit" aria-hidden="true" style={{marginLeft:"10px"}}></i>

                         </a>

                         </td>
                        <td>
                          <a onClick={()=>navigateToCourseDetails(item)} style={{cursor:'pointer',color:'blue'}}>
                          {item.course_name}
                          </a>
                         
                          </td>
                          <td>{item.original_price}</td>

                        <td>{item.discounted_price}</td>
                        <td>
                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                         
                          </td>
                      </tr>
                    ))}
                  
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>
</div>
           </div>
         
        </main>
        </>
        )
        
    }
  return (
   <Template>{coursesScreen()}</Template>
  )
}

export default Courses