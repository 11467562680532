import React, { useEffect, useState } from 'react'
import PopModal from './PopModal'
import { useCreateCourseSectionMutation, useCreateLessonNameMutation, useDeleteLessonMutation, useDeleteSectionMutation, useUpdateLessonNameMutation, useUpdateSectionNameMutation } from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import WarningModal from './WarningModal';
import { Uploader } from '../utils/upload';
import VideoPlayer from './VideoPlayer';
import { Config } from '../Config';


function CourseSection({course_details,refetch}) {
 
    console.log("course_details",course_details)

    console.log("course_details id",course_details.data[0]._id)

    const[course_details_local,setCourseDetailsLocal]=useState([])
    const [lessonProgress, setLessonProgress] = useState();
    const [showModal, setShowModal] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [deleteModalTitle, setdeleteModalTitle] = useState(false);
    const [isSectionDelete, setIsSectionDelete] = useState(false);
    
    const [showModalDeleteLesson, setShowModalDeleteLesson] = useState(false);
    const [isLessonDelete, setIsLessonDelete] = useState(false);

    const [showModalLesson, setShowModalLesson] = useState(false);
    const [section_id, setSectionId] = useState("");
    const [section_name, setSectionName] = useState("");
const[isSectionUpdate,setIsSectionUpdate]=useState(false)
const[uploadButtonSubmitting,setUploadButtonSubmitting]=useState(false)
    const[lesson_section_id,setLessonSectionId]=useState("")
    const[lesson_section_name,setLessonSectionName]=useState("")

    const [lesson_name, setLessonName] = useState("");
    const [lesson_id, setLessonId] = useState("");

const[isLessonUpdate,setIsLessonUpdate]=useState(false)
    const [CreateCourseSection, { data, isSuccess, isLoading, isError, error }] = useCreateCourseSectionMutation();
    const [CreateLessonName, { data:lessondata, isSuccess:isLessonSuccess, isLoading:isLessonLoading, isError:isLessonError}] = useCreateLessonNameMutation();
    const [UpdateLessonName, { data:lessonDataUpdate, isSuccess:isLessonSuccessUpdate, isLoading:isLessonLoadingUpdate, isError:isLessonErrorUpdate}] = useUpdateLessonNameMutation();
    const [UpdateSectionName, { data:sectionDataUpdate, isSuccess:isSectionSuccessUpdate, isLoading:isSectionLoadingUpdate, isError:isSectionErrorUpdate}] = useUpdateSectionNameMutation();
    const [DeleteSection, { data:sectionDataDelete, isSuccess:isSectionSuccessDelete, isLoading:isSectionLoadingDelete, isError:isSectionErrorDelete}] = useDeleteSectionMutation();
    const [DeleteLesson, { data:lessonDataDelete, isSuccess:isLessonSuccessDelete, isLoading:isLessonLoadingDelete, isError:isLessonErrorDelete}] = useDeleteLessonMutation();
    
    const [video_file, setVideoFile] = useState(undefined)
    const[lesson_number,setLessonNumber]=useState("0")
    const [uploader, setUploader] = useState(undefined)
    const[progress_bar_percentage,setProgressBarPercentage]=useState(0)
    const[showProgressBar,setShowProgressBar]=useState(false)
    const [fileUploaded, setFileUploaded] = useState(false);
  //  const[course_id,setCourseId]=useState()

//const[course_id,setCourseId]=useState("")
//const[course_data_length,setCourseDataLength]=useState()
const [rerenderChild, setRerenderChild] = useState(false);
    let course_id=course_details.data[0]._id
let course_data_length=course_details.data[0].course_data.length
console.log("course_data_length",course_data_length)
useEffect(()=>{
  setCourseDetailsLocal(course_details)
 // setCourseId(course_details.data[0]._id)
 // setCourseId(course_details.data[0]._id)
  //setCourseDataLength(course_details.data[0].course_data.length)
},[])
useEffect(()=>{
  console.log("Refetch called")
  setCourseDetailsLocal(course_details)
  setRerenderChild(!rerenderChild);
},[refetch])
useEffect(()=>{
  console.log("in lesson delete useeffect")
  if(isLessonDelete)
  {
  console.log("use effect lesson",course_id)
  console.log("use effect lesson section_id",section_id)
  console.log("use effect lesson lesson_id",lesson_id)

      DeleteLesson({
          course_id:course_id,
          section_id:section_id,
          lesson_id:lesson_id
      })
     
  }
},[isLessonDelete])
    const handleOpenModal = () => {
      setShowModal(true);
    };
    const handleOpenModalLesson = (section_id,lesson_section_name) => {
        console.log("SectionID",section_id)
        setLessonSectionId(section_id)
        setLessonSectionName(lesson_section_name)
        setShowModalLesson(true);
      };
  
      const handleKeypressSection = e => {
    
        if (e.keyCode === 13) {
            handleSubmit();
        }
      };
    const handleSubmit = () => {
        if(isSectionUpdate)
        {
            UpdateSectionName({
                course_id:course_id,
                section_id:section_id,
                new_section_name:section_name
            })
        }
        if(!isSectionUpdate)
        {
        CreateCourseSection({
            course_id:course_id,
            section_name:section_name
        })
    }
    }
    const handleKeypressLesson = e => {
    
        if (e.keyCode === 13) {
            handleSubmitLesson();
        }
      };
    const handleSubmitLesson = () => {
        if(isLessonUpdate)
        {
            UpdateLessonName({
                lesson_id:lesson_id,
                course_id:course_id,
                section_id:lesson_section_id,
                new_lesson_name:lesson_name
            })
        }
        if(!isLessonUpdate)
        {
        CreateLessonName({
            course_id:course_id,
            section_id:lesson_section_id,
            lesson_name:lesson_name
        })
    }
    };
    const clearLesson=()=>{
        setLessonName("")
        setLessonId("")
    }
    const clearSection=()=>{
        setSectionName("")
        setSectionId("")
      
    }
  useEffect(()=>{
    if(isSuccess)
    {
        toast.success(data.message)
        clearSection()
    }
  },[data])
  useEffect(()=>{
    if(isSectionSuccessUpdate)
    {
        toast.success(sectionDataUpdate.message)
        setIsSectionUpdate(false)
        setShowModal(false)
        clearSection()
    }
  },[sectionDataUpdate])
  useEffect(()=>{
    if(isLessonSuccess)
    {
        toast.success(lessondata.message)
        clearLesson()
    }
  },[lessondata])

  useEffect(()=>{
    if(isLessonSuccessUpdate)
    {
        toast.success(lessonDataUpdate.message)
        setIsLessonUpdate(false)
        setShowModalLesson(false)
        clearLesson()
    }
  },[lessonDataUpdate])
  const updateLessonName=(section_id,lesson_id,lesson_name)=>{
    console.log("In update lesson",lesson_name,lesson_id)
setLessonName(lesson_name)
setIsLessonUpdate(true)
setShowModalLesson(true)
setLessonId(lesson_id)
setLessonSectionId(section_id)

  }
  const updateSectionName=(section_id,section_name)=>{
    console.log("In update lesson",section_name,section_id)
    setSectionId(section_id)
setSectionName(section_name)
setIsSectionUpdate(true)
setShowModal(true)
  }
  const deleteSectionName=(section_id,lesson_length)=>{
    console.log("lesson_length",lesson_length)
    console.log("In delete section",section_id)
    if(lesson_length>0)
    {
        return alert("First delete lessons inside")
    }
    setSectionId(section_id)
setShowModalDelete(true)
  }
  const deleteLesson=(section_id,lesson_id)=>{
  
    setSectionId(section_id)
    setLessonId(lesson_id)
setShowModalDeleteLesson(true)
  }
  useEffect(()=>{
 

    if(isSectionDelete)
    {
        DeleteSection({
            course_id:course_id,
            section_id:section_id
        })
       
    }
  },[isSectionDelete])
  useEffect(()=>{
    if(isSectionSuccessDelete)
    {
       toast.success(sectionDataDelete.message)
      setSectionId("") 
      setIsSectionDelete(false)
      setShowModalDelete(false)
    }
  },[sectionDataDelete])
 
  useEffect(()=>{
    if(isLessonSuccessDelete)
    {
       toast.success(lessonDataDelete.message)
      setSectionId("") 
      setLessonId("")
      setIsLessonDelete(false)
      setShowModalDeleteLesson(false)
    }
  },[lessonDataDelete])
  const updateLessonProgress = (lessonId, percentage) => {
    setLessonProgress({
      ...lessonProgress,
      [lessonId]: percentage,
    });
  };
  const handleRefetch=()=>{
    refetch()
  }
  const renderProgressBar = (lessonId) => {
   // const percentage = lessonProgress[lessonId] ;
   const percentage = lessonProgress ;

    return(
      <>
      {lessonId==lesson_number? <div class="progress">
  <div class="progress-bar bg-success" 
  role="progressbar" 
  style={{width: percentage+"%"}} 
  aria-valuenow="100"
   aria-valuemin={percentage}
   
  aria-valuemax="100">    
  </div>
</div>:""}
      </>
    )
  };
  const upload_video = (section_id, lesson_id) => {
    setLessonNumber(lesson_id);
    setUploadButtonSubmitting(true)

    const lastPeriodIndex = video_file.name.lastIndexOf('.');
    let video_file_name = video_file.name.replace(/ /g, "-").replace(/\./g, (match, index) => {
      return index === lastPeriodIndex ? match : "-";
    }).substring(0, video_file.name.lastIndexOf('.'));
  
    if (video_file) {
      const videoUploaderOptions = {
        fileName: video_file_name,
        file: video_file,
        course_id: course_id,
        section_id: section_id,
        lesson_id: lesson_id,
        content_type: "recorded_video"
      }
      let percentage = undefined
  
      const uploader = new Uploader(videoUploaderOptions);
      setUploader(uploader);
  
    //  let lastPercentage = -1; // Initialize with a value that's out of the percentage range
  
      uploader
        .onProgress(({ percentage:newPercentage }) => {
          if (newPercentage !== percentage) { // Only update if the percentage has changed
            setShowProgressBar(true);
            percentage = newPercentage
            console.log(`${percentage}%`);
            setLessonProgress(percentage)
           
            //updateLessonProgress(lesson_id, percentage);
           // lastPercentage = percentage; // Update the lastPercentage
          }
          if (percentage === 100 ) {
           // setFileUploaded(true)
           percentage=undefined
          
            toast.success("File uploaded successfully");
           
           setShowProgressBar(false);
            // setTimeout(() => {
            //   setShowProgressBar(false);
            // }, 2000);
          }
        })
        .onError((error) => {
          setVideoFile(undefined);
          console.error(error);
        });
  
      uploader.start();
    } else {
      console.log("File not selected");
    }
  }
  return (
    <>
     <ToastContainer/>
     
    <div className='row'>
    <div className='col-md-12'>
    <button className="btn btn-primary"
      onClick={()=>handleOpenModal()}
      >
        Add Section
      </button>
    </div>
    </div>
    <div className='row' style={{ marginTop: "20px" }}>
        <div className='col-md-12'>
          {course_data_length > 0 ?
            course_details.data[0].course_data.map((item, i) => {
              return (
                <div key={i}>
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id={"heading" + i}>
                        <div className='d-flex justify-content-between'>
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#section" + item.section_number}
                            aria-expanded="false"
                            aria-controls={"section" + item.section_number}
                          >
                            {item.section_name}
                            <i className="fa fa-edit"
                              aria-hidden="true"
                              style={{ marginLeft: "10px" }}
                              onClick={() => updateSectionName(item._id, item.section_name)}
                            />
                            <i className="fa fa-trash"
                              onClick={() => deleteSectionName(item._id, item.lessons.length)}
                              aria-hidden="true"
                              style={{ marginLeft: "20px" }}
                            ></i>
                          </button>
                          <button
                            className="btn btn-warning"
                            onClick={() => handleOpenModalLesson(item._id, item.section_name)}
                          >
                            Add Lesson
                          </button>
                        </div>
                      </h2>
                      <div id={"section" + item.section_number}
                        className="accordion-collapse collapse"
                        aria-labelledby={"heading" + i}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <ol start={1} className="lesson-list">
                            {item.lessons.map((lessonInfo, lessonIndex) => {
                              return (
                                <li key={lessonIndex} className="lesson-li" >
                                  {lessonInfo.lesson_name}
                                  <i className="fa fa-edit"
                                    aria-hidden="true"
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => updateLessonName(item._id, lessonInfo._id, lessonInfo.lesson_name)}
                                  />
                                  <i className="fa fa-trash"
                                    onClick={() => deleteLesson(item._id, lessonInfo._id)}
                                    aria-hidden="true"
                                    style={{ marginLeft: "20px" }}
                                  ></i>
                                   <input
                      accept="video/*" 
  style={{marginLeft:"10px"}}
          type="file"
         
          onChange={(e) => {
            setVideoFile(e.target?.files?.[0])
          }}
        />  
        <button type="button" 
        class="btn btn-primary"
   onClick={()=>upload_video(item.section_number,lessonInfo.lesson_number)}

        >Upload Video
        </button> 
        <button type="button" 
        class="btn btn-primary"
        style={{marginLeft:"10px"}}
   onClick={()=>handleRefetch()}

        >Refresh
        </button> 
      {  showProgressBar  ?
//       <div class="progress">
//   <div class="progress-bar bg-success" 
//   role="progressbar" 
//   style={{width: progress_bar_percentage+"%"}} 
//   aria-valuenow="100"
//    aria-valuemin="0"
   
//   aria-valuemax="100">    
//   </div>
// </div>
renderProgressBar(lessonInfo.lesson_number)
:
""}
{lessonInfo.media_url && lessonInfo.file_converted_status==true? 
     <>
     <div className='col-md-3'>
     <VideoPlayer shouldRerender={rerenderChild} media_url={`${Config.API_CLOUDFRONT}${lessonInfo.media_url}`}/>

     </div>
     
     </>
     
     :""}
      
                                </li>
                              )
                            })}
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }) : <></>}
        </div>
      </div>

   {
   showModal? 
   <PopModal 
   setShowModal={setShowModal}
   modalTitle={isSectionUpdate?"Update Course Section":"Create Course Section"}
   > 
        <>
        <div className="form-group">
                    <label htmlFor="inputField">Section Name</label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="inputField"
                      name="section_name"
                      value={section_name}
                      onKeyDown={handleKeypressSection}
                     onChange={(e)=>setSectionName(e.target.value)}
                    />
                  </div>
                  <button type="submit" 
                  className="btn btn-primary" 
                 
                  onClick={()=>handleSubmit()}>
                    {isSectionUpdate?"Update Section": "Save Section"}
                  </button>
        </>
    </PopModal>:""
    }
    {
   showModalLesson? 
   <PopModal 
   setShowModal={setShowModalLesson}
   modalTitle={updateLessonName?"Update Lesson for - "+ lesson_section_name:"Create Lesson for - "+ lesson_section_name}
   > 
        <>
        <div className="form-group">
                    <label htmlFor="inputField">Enter Lesson Name</label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="inputField"
                      name="lesson_name"
                      value={lesson_name}
                      onKeyDown={handleKeypressLesson}

                     onChange={(e)=>setLessonName(e.target.value)}
                    />
                  </div>
                  <button type="submit" 
                  className="btn btn-primary"                  
                  onClick={()=>handleSubmitLesson()}
                  disabled={isLessonLoading || isLessonLoadingUpdate?true:false}

                  >
                   {isLessonUpdate?"Update Leeson": "Save Lesson"}
                  </button>
        </>
    </PopModal>:""
    }
    {
        showModalDelete?<WarningModal
         setShowWarningModal={setShowModalDelete}
         modalTitle={"Delete Section"}
         setIsDelete={setIsSectionDelete}
         showModal={showModalDelete}
         />:""

    }
    {
        showModalDeleteLesson?
        <WarningModal
         setShowWarningModal={setShowModalDeleteLesson}
         modalTitle={"Delete Lesson"}
         setIsDelete={setIsLessonDelete}
         showModal={showModalDeleteLesson}
         />:""

    }
    </>
  )
}

export default CourseSection