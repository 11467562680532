import React, { useEffect, useState } from 'react'
import Template from '../Components/Template'

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearCourseData } from '../Store/CourseData';
import PopModal from '../Components/PopModal';
import CourseSection from '../Components/CourseSection';
import { useGetCourseByIdQuery } from '../Services/modules/course';
import CourseThumbnailImage from '../Components/CourseThumbnailImage';
import CouponCode from '../Components/CouponCode';
import CourseIntroVideo from '../Components/CourseIntroVideo';
import Resources from '../Components/Resources';
import CourseFreeVideos from '../Components/CourseFreeVideos';

function CourseContent() {
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const is_course_data=useSelector(state => state.courseData.course_data?true:false);
    const course_data=useSelector(state => state.courseData.course_data);  
   let course_id_from_redux=course_data._id
    
  const[course_id,setCourseId]=useState("")
  const[courseName,setCourseName]=useState("")
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
 
useEffect(()=>{
    if(!is_course_data)
    {
        navigate("/courses")
    }
    else
    {
        console.log("Course details in use effect",course_data)
        setCourseId(course_data._id)
        setCourseName(course_data.course_name)
            
    }

    // return () => {
    //     dispatch(clearCourseData({}))
    // };
},[])
const { 
  refetch,
    data,  
    isLoading,
    isSuccess,
    isError
   } = useGetCourseByIdQuery({course_id:course_id_from_redux});
 useEffect(()=>{console.log("data refetched")},data)
 const handleRefetch = () => {
  refetch();
};
    const courseContentScreen=()=>{
        return(
           <>
           <main id="main" class="main">
           
            <div className='container'>
            <div class="alert alert-primary" role="alert">
<h3 style={{textAlign:'center'}}>
    Create Content For Course- {courseName}
    </h3> 
</div>
            </div>
            <div className='container'>
              
{/* <PopModal/> */}
{!isLoading?
  <div className="row">
<CourseSection 
course_id={course_id}
course_details={data}

refetch={handleRefetch}

/>
</div>
:
""
}
{!isLoading?
 <div className="row" style={{marginTop:"20px"}}>
<CourseThumbnailImage 
refetch={refetch}
course_details={data}
/>
</div>
:
""
}
{!isLoading?
 <div className="row" style={{marginTop:"20px"}}>
<CourseIntroVideo 
refetch={refetch}
course_details={data}
/>
</div>
:
""
}
{!isLoading?
 <div className="row" style={{marginTop:"20px"}}>
<CouponCode 
refetch={refetch}
course_details={data}
/>
</div>
:
""
}
{!isLoading?
 <div className="row" style={{marginTop:"20px"}}>
<Resources 
refetch={refetch}
course_details={data}
/>
</div>
:
""
}
{!isLoading?
 <div className="row" style={{marginTop:"20px"}}>
<CourseFreeVideos 
refetch={refetch}
course_details={data}
/>
</div>
:
""
}
            </div>

           </main>
           </>
        )
    }
  return (
    <Template>{courseContentScreen()}</Template>
  )
}

export default CourseContent