export default build =>
    build.mutation({
        query(payload) {
            console.log("payload",payload)
            return {
                url: `/course/getAllCouponCodesByCourse?course_id=${payload.course_id}`,
                method: 'POST',
                body: payload,
            };
        }
       
    })
