import React,{useState,useEffect} from 'react'
import Template from '../Components/Template'
import DataTable from '../Components/DataTable'
import useDataTable from '../hooks/useDataTable'
import { MdOutlineNavigateNext,MdSkipNext,MdSkipPrevious,MdEdit, MdDelete   } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import { useDeleteUserMutation, useGetAllStudentsQuery } from '../Services/modules/reports';
import { useGetAllCoursesQuery, useGetCouponCodeByCourseMutation, useGetCouponCodesQuery } from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WarningModal from '../Components/WarningModal';

function Reports() {
  const [allPurchaseStatus, setPurchaseStatus] = useState(
    [
      
      {id:"1",purchase_status:"Enrolled"},
      {id:"2",purchase_status:"PaymentInitiated"}

  ]);  
  const [allUsers, setAllUsers] = useState([]);   
  const[allCourses,setAllCourses]=useState([])
  const[allCouponCodes,setAllCouponCodes]=useState([])
const[selectedCourse,setSelectedCourse]=useState("")
const[selectedCoupon,setSelectedCoupon]=useState("")
const[selectedPurchaseStatus,setSelectedPurchaseStatus]=useState("")
const[student_id,setStudentId]=useState("")
const[isDelete,setIsDelete]=useState(false) 
const[isDeleteModal,setDeleteModal]=useState(false)
  const {   
    handleFirstPage ,
    handlePageSizeChange,
    handleLastPage,
    sequenceStartIndex  ,
    page,
    rowsPerPage,
    totalRows,
    setTotalRows,
    setPage,
    setTotalPages,
    totalPages
    // Add other state variables and functions here
  }=useDataTable()
  const{data:dataStudents=[],
    isSuccess:isSuccessStudents,
    isLoading:isLoadingStudents,
    error:errorStudents,
    refetch
  }=useGetAllStudentsQuery(
    {
    page_number:page,
    page_size:rowsPerPage,
    page_size:rowsPerPage,
    purchase_status:selectedPurchaseStatus,
    course_id:selectedCourse,
    coupon_code: selectedCoupon 
  }
  )
useEffect(()=>{
if(isSuccessStudents)
{
  console.log("dataStudents",dataStudents)
  setAllUsers(dataStudents.data)
  setTotalRows(dataStudents.totalRows)

}
},[dataStudents])

const{data:dataCourses=[],
  isSuccess:isSuccessCourses,
  isLoading:isLoadingCourses,
  error:errorCourses,
  refetch:refetchCourses
}=useGetAllCoursesQuery()

useEffect(()=>{
  if(isSuccessCourses)
  {
   // console.log("dataCourses",dataCourses)
    setAllCourses(dataCourses.data)
  }
},[dataCourses])

const[GetCouponCodes,
  {data:dataCouponCode=[],
  isSuccess:isSuccessCouponCode,
  isLoading:isLoadingCouponCode,
  error:errorCouponCode,
  refetch:refetchCouponCode}
]=useGetCouponCodeByCourseMutation()
useEffect(()=>{
  if(isSuccessCouponCode)
  {
  //  console.log("dataCouponCode",dataCouponCode)
    setAllCouponCodes(dataCouponCode.data)
   // setAllCourses(dataCourses.data)
  }
},[dataCouponCode])
const[DeleteUser,
  {data:dataDeleteUser=[],
  isSuccess:isSuccessDeleteUser,
  isLoading:isLoadingDeleteUser,
  error:errorDeleteUser,
  refetch:refetchDeleteUser}
]=useDeleteUserMutation()

useEffect(()=>{
  if(isSuccessDeleteUser)
  {
    toast.success("Student deleted successfully")
    setDeleteModal(false)
    setIsDelete(false)
   setStudentId("");

  }
},[dataDeleteUser])

const handleCourseChange = (selectedCourseValue) => {
 // console.log("selectedCourse",selectedCourseValue)
  setSelectedCourse(selectedCourseValue);
  GetCouponCodes({course_id:selectedCourseValue})
 // refetchCouponCode({course_id:selectedCourseValue})
 // refetch()
};
const handleCouponChange = (selectedCoupon) => {
 // console.log("selectedCoupon",selectedCoupon)
  setSelectedCoupon(selectedCoupon); 
};
const handlePurchaseStatusChange = (selectedPurchase) => {
  console.log("selectedPurchase",selectedPurchase)
  setSelectedPurchaseStatus(selectedPurchase); 
 // refetch()

};
const applyFilter=()=>{
 // refetch()
}
const handleStudentDelete=(dataRow)=>{
  console.log("In handle student delete",dataRow)
  setStudentId(dataRow._id);
   setDeleteModal(true)
  

 }
 useEffect(() => {
  if(isDelete)
  {
    DeleteUser({student_id:student_id})
  }

}, [isDelete]);

    const ReportsScreen=()=>{
       
      return(
        <>
         <
                WarningModal
                setShowWarningModal={setDeleteModal}
                modalTitle={"Delete Product"}
                setIsDelete={setIsDelete}
                showModal={isDeleteModal}
              />
         <ToastContainer/>
        <main id="main" class="main">
        <div className='container'>
        <div class="row" style={{marginBottom:"20px"}}>
        <div className='col-md-4'>
        <label>Enrolled Status</label>
  <select value={selectedPurchaseStatus} 
className='form-control'
onChange={(e) => handlePurchaseStatusChange(e.target.value)}>
      <option value="">All</option>
      {allPurchaseStatus.map((purchaseStatus) => 
      {
       // console.log("purchaseStatus",purchaseStatus)
       return (
        <option key={purchaseStatus.id} value={purchaseStatus.purchase_status}>{purchaseStatus.purchase_status}</option>
      )}
      )}
    </select>
  </div>
 
  <div className='col-md-4'>
  <label>Course</label>
  <select value={selectedCourse} 
className='form-control'
onChange={(e) => handleCourseChange(e.target.value)}>
      <option value="">All</option>
      {allCourses.map((course) => (
        <option key={course._id} value={course._id}>{course.course_name}</option>
      ))}
    </select>
  </div>
  <div className='col-md-4'>
    <label>Coupon Code</label>
  <select value={selectedCoupon} 
className='form-control'
onChange={(e) => handleCouponChange(e.target.value)}>
      <option value="">NA</option>
      {allCouponCodes.map((couponCode) => 
      {
      //  console.log("eventData",couponCode)
       return (
        <option key={couponCode._id} value={couponCode.code}>{couponCode.code}</option>
      )}
      )}
    </select>
  </div>
  {/* <button
                  type="submit"
                  className="btn btn-primary btn-block mt-3"
                  onClick={() => applyFilter()}
                 // disabled={!isFormValid || isLoading || isSumbmiting} 
                >
                
                 Submit
                </button> */}
 
    </div>

        <div className='row'>
        <div className="col-md-12">
                  <div className="card">
                  <div className='card-body'>
                        <table className="bordered-table">
        <thead>
          <tr>
          <th>Action</th>
            <th>Index</th>
            <th>Student Name</th>
            {/* Add more column headers as needed */}
          </tr>
        </thead>
        <tbody>
          {allUsers.map((item, index) => (
            <tr key={index}>
              <td>
                
              <MdDelete 
              onClick={()=>handleStudentDelete(item)} 
              style={{cursor:'pointer'}}
              />
              </td>
              <td>{sequenceStartIndex + index}</td>
              <td>
              <a 
              //onClick={()=>navigateToProductDetails(item)} 
              style={{cursor:'pointer',color:'blue'}}>

                {item.first_name} {item.last_name}
                </a>
                </td>             
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => handleFirstPage()} disabled={page === 0}><MdSkipPrevious/></button>
      <button onClick={() => setPage(page - 1)} disabled={page === 0}><GrFormPrevious/></button>
      <button onClick={() => setPage(page + 1)} disabled={page === totalPages - 1}><MdOutlineNavigateNext /></button>
      <button onClick={() => handleLastPage()} disabled={page === totalPages - 1}><MdSkipNext/></button>
      <div>
        <select value={rowsPerPage} onChange={handlePageSizeChange}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      
      </div>
     
                        </div>
                   </div>
                   </div>
                   </div>
                   </div>
      
        </main>
      
       </>
      )
     
    }
  return (
    <Template>
        {ReportsScreen()}
    </Template>
  )
}

export default Reports