import React,{useEffect} from 'react'

import { 
 
    Route,
    Routes,     
    Navigate,
    Outlet,
  
   } from 'react-router-dom'

import Home from './Pages/Home'
import Login from './Pages/Login';
import { useSelector } from 'react-redux';
import Courses from './Pages/Courses';
import CourseContent from './Pages/CourseContent';
import Reports from './Pages/Reports';

function AppRoutes() {
  const isLoggedin = useSelector(state => state.userData.user_data == null ? false : true);
  
    const ProtectedRoute = ({
        isAllowed,
        redirectPath = '/',
        children,
      }) => {
        if (!isAllowed) {
          return <Navigate to={redirectPath} replace />;
        }
      
        return children ? children : <Outlet />;
      };
  return (
    <>
 
   <Routes>
   <Route index element={<Login/>}/>
   <Route element={<ProtectedRoute isAllowed={isLoggedin} />}>
   <Route path="/dashboard" element={<Home/>}/>    
   <Route path="/courses" element={<Courses/>}/>       
   <Route path="/coursecontent" element={<CourseContent/>}/>   
   <Route path="/reports" element={<Reports/>}/>   

   {/* <Route path="dashboard" element={<Reports/>}/>     */}


        </Route>
    </Routes>
  
  
    </>
  )
}

export default AppRoutes