import React from 'react'
import { Modal, Button } from 'react-bootstrap';

function WarningModal({setShowWarningModal,modalTitle,setIsDelete,showModal}) {
    const handleCloseModal = () => {
        setShowWarningModal(false);
      };
      const handleDelete = () => {
        console.log("setIsDelete",setIsDelete)
        console.log("in handle delete")
        setIsDelete(true);
      };
  return (
    <>
     <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this record? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default WarningModal