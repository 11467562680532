import React, { useEffect, useState } from 'react'
import { useCreateCourseThumbnailImageMutation } from '../Services/modules/course';
import { toast } from 'react-toastify';
import{Config} from '../Config'
import axios from 'axios';
import { useSelector } from 'react-redux';
function CourseIntroVideo({course_details,refetch}) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
 
   
    const userToken = useSelector(state => state.userData.user_data.token);
   
    let course_id=course_details.data[0]._id
    let intro_video=course_details.data[0].course_intro_video_link
    console.log(`${Config.API_URL_IMAGE}/CourseIntroVideos/${intro_video}`)
    console.log("course_details.data[0]",course_details.data[0])
    const[introVideo,setIntroVideo]=useState(intro_video)
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
      };
    
      const handleUpload = async () => {
        if (!selectedFile) {
          toast.error('Please select an image to upload.');
          return;
        }  
        const formData = new FormData();
        formData.append('intro_video', selectedFile);
    formData.append("course_id",course_id)
        try {
          const response = await axios.post(`${Config.API_URL_LOCAL}/course/uploadintrovideo`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${userToken}`,
            },
            onUploadProgress: (progressEvent) => {
              const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
              setUploadProgress(progress);
            },
          });
    
          // Call refetch when upload is successful
          refetch();
    
          toast.success(response.data.message);
          setTimeout(()=>{setUploadProgress(0)},2000)
        } catch (error) {
          toast.error('Error uploading image.');
        }
      };
      useEffect(()=>{
        setIntroVideo(intro_video)
    },[intro_video])
  return (
    <>
    <div className='row' style={{marginTop:"50px"}}>
    <div className="row">
        <div className="col-md-6">
          <input type="file" accept="video/mp4" onChange={handleFileChange} />
          <button className="btn btn-primary" onClick={handleUpload}>
            Upload Intro Video
          </button>
        </div>
      </div>
      {uploadProgress > 0 && (
        <div className="row mt-3">
          <div className="col-md-6">
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${uploadProgress}%` }}
                aria-valuenow={uploadProgress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {uploadProgress}%
              </div>
            </div>
          </div>
         
        </div>
         )}
          <div className="col-md-6" style={{marginTop:"40px"}}>
        <div className="card" style={{ width: '18rem' }}>
          
          {introVideo ? (
            <>
            
            <video controls style={{ maxWidth: '100%', maxHeight: '300px' }}>
          
            <source 
            src={`${Config.API_URL_IMAGE}/CourseIntroVideos/${introVideo}`} type="video/mp4"></source>

            Your browser does not support the video tag.
          </video>
            
            </>
           
          ) : (
            <div className="card-body">
              <p className="card-text">No video uploaded yet.</p>
            </div>
          )}
        </div>
        </div>
    </div>
    </>
  )
}

export default CourseIntroVideo