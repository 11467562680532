export const Config = {
   
     // API_URL_LOCAL: 'http://localhost:8000/api/',
    // API_URL_LOCAL: 'https://testbackend.skilluphere.com/api/',
    // API_URL_IMAGE: 'https://testbackend.skilluphere.com',
     // API_URL_IMAGE: 'http://localhost:8000',
    API_URL_LOCAL: 'https://backend.skilluphere.com/api/',
    API_URL_IMAGE: 'https://backend.skilluphere.com',
   API_CLOUDFRONT:'https://d26kkjzvx05c5e.cloudfront.net',

  //API_URL_LOCAL: 'https://backend.sanglishikshansanstha.com/api/'
   
   }
   