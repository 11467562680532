import React, { useEffect, useState } from 'react';
import { useCreateCouponCodeMutation, useDeleteCouponMutation, useEditCouponMutation, useGetCouponCodesQuery } from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WarningModal from './WarningModal';

function CouponCode({ course_details }) {
  console.log("coupon code course id", course_details.data[0]._id);
  const [CouponCode, setCouponCode] = useState({
    code: "",
    discount_percentage: "",
    couponCodeDescountedPrice: "",
    is_active: "",
    course_id: course_details.data[0]._id,
    coupon_id:"",
    discounted_price: course_details.data[0].discounted_price,
  });
  const[isSumbmiting,setIsSubmiting]=useState(false)
  const [isFormValid, setIsFormValid] = useState(false);
  const[couponData,setCouponData]=useState([])
  const[deleteModal,setDeleteModal]=useState(false)
  const[deleteCoupon,setDeleteCoupon]=useState(false)
  const[isEdit,setisEdit]=useState(false)
  const[coupon_id,setCouponId]=useState("")
  const [CreateCouponCode, { data, isSuccess, isLoading, isError, error }] = useCreateCouponCodeMutation();

  useEffect(() => {
    setIsSubmiting(false)
    clearForm()
    setIsFormValid(false);

    if (isSuccess) {
      if (data.error === false) {
        toast.success(data.message);
      }
      if (data.error === true) {
        toast.error(data.message);
      }
    }
  }, [data]);
  const { 
    data: allCoupons= [],  
    isLoading:loading=true,
    isSuccess:isSuccessCoupons,
    isError:isErrorCoupons
   } = useGetCouponCodesQuery({course_id:course_details.data[0]._id});
 
   useEffect(() => {    
    if (isSuccessCoupons) {
        console.log("InSuccess",data);
      
        if(allCoupons.error==true)
        {
          toast.error(data.message)          
          return 
        }
        else
        {
          setCouponData(allCoupons.data)
           
        }
     
    }
    if (isError) {
        alert("In error")
        console.log(error);
    }
}, [allCoupons]);
const [DeleteCoupon, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteLoading, isError:deleteError }] = useDeleteCouponMutation({course_id:course_details.data[0]._id,coupon_id:coupon_id});
const [EditCoupon, { data:editData, isSuccess:editSuccess, isLoading:editLoading, isError:editError }] = useEditCouponMutation({course_id:course_details.data[0]._id,coupon_id:coupon_id});
  useEffect(()=>{
    setCouponId("")
    setDeleteModal(false)
    setDeleteCoupon(false)
    if(deleteSuccess)
    {
      if(deleteData.error==false)
      {
        toast.success(deleteData.message)

      }
      if(deleteData.error==true)
      {
        toast.error(deleteData.message)

      }
    }
  },[deleteData])
  useEffect(()=>{
    setCouponId("")
    setisEdit(false)
    setIsSubmiting(false)
    clearForm()
    if(editSuccess)
    {
      if(editData.error==false)
      {
        toast.success(editData.message)

      }
      if(editData.error==true)
      {
        toast.error(editData.message)

      }
    }
  },[editData])
  useEffect(()=>{
    if(deleteCoupon)
    {
      DeleteCoupon({
        course_id:course_details.data[0]._id,
        coupon_id:coupon_id
      })
    }
   },[deleteCoupon])
   const calculateDiscountedPrice = () => {
    const { discounted_price, discount_percentage } = CouponCode;
    if (discounted_price !== "" && discount_percentage !== "") {
      const discountedPercentage = parseFloat(discount_percentage);
      const originalPrice = parseFloat(discounted_price);
      const discountAmount = (discountedPercentage / 100) * originalPrice;
      const discountedPrice = originalPrice - discountAmount;
      const discountedPriceInteger = Math.floor(discountedPrice);
      setCouponCode({ ...CouponCode, couponCodeDescountedPrice: discountedPriceInteger });
    }
  };

  const validateForm = () => {
    const { code, discount_percentage, couponCodeDescountedPrice, is_active } = CouponCode;
    if (code !== "" && discount_percentage !== "" && couponCodeDescountedPrice !== "" && is_active !== "") {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setCouponCode({
      ...CouponCode,
      [e.target.name]: value,
    });
    validateForm();
  };

  const handlePercentageBlur = () => {
    calculateDiscountedPrice();
  };


  const onSubmit = () => {
    setIsSubmiting(true)
    if(isEdit)
    {
      EditCoupon(CouponCode)
    }
    else
    {
      CreateCouponCode(CouponCode);

    }
  };
  const clearForm=()=>{
       
    setCouponCode({
      ...CouponCode,
      code:"",
    discount_percentage:"",
    couponCodeDescountedPrice:""   ,
    is_active:""
      
    })
  }
  const deleteCouponConfirmAlert=(record)=>{   
    setDeleteModal(true)   
    setCouponId(record._id)  
   }
   const editCouponCode=(record)=>{
    console.log("Edit record",record)
    setisEdit(true)
   // setCouponCode(record._id)
    setCouponCode({
      ...CouponCode,
      code:record.code,
    discount_percentage:record.discount_percentage,
    couponCodeDescountedPrice:record.couponCodeDescountedPrice   ,
    is_active:record.status,
    coupon_id:record._id,
    course_id:course_details.data[0]._id,
      
    })

   }
  
  return (
    <>
      <ToastContainer />
     {
      deleteModal?
      <WarningModal
      setShowWarningModal={setDeleteModal}
      modalTitle={"Delete Lesson"}
      setIsDelete={setDeleteCoupon}
      showModal={deleteModal}
      />:""
      
    }
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Create coupon codes, Course price is rs {course_details.data[0].discounted_price}</h5>
            <div className="form-group">
              <label htmlFor="input1">Code</label>
              <input
                type="text"
                className="form-control mb-3"
                id="input1"
                name="code"
                value={CouponCode.code}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="input2">Discount in percentage</label>
              <input
                type="text"
                className="form-control mb-3"
                id="input2"
                name="discount_percentage"
                value={CouponCode.discount_percentage}
                onChange={handleChange}
                onBlur={handlePercentageBlur}
              />
            </div>
            <div className="form-group">
              <label htmlFor="input3">Discounted Price On Apply</label>
              <input
                type="text"
                className="form-control mb-3"
                id="input3"
                name="couponCodeDescountedPrice"
                value={CouponCode.couponCodeDescountedPrice}
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="input4">Status</label>
              <select
                className="form-control mb-3"
                id="input4"
                name="is_active"
                value={CouponCode.is_active}
                onChange={handleChange}
              >
                <option value={""}>--Select--</option>
                <option value={"active"}>Active</option>
                <option value={"inactive"}>Inactive</option>
              </select>
            </div>
          
             <button
                  type="submit"
                  className="btn btn-primary btn-block mt-3"
                  onClick={() => onSubmit()}
                  disabled={isLoading || isSumbmiting} 
                >
                  {isLoading || isSumbmiting ? 
                  'Submitting...' : 
                  isEdit?'Update':
                  'Submit'} 
                </button>
          </div>
        </div>
      </div>
      <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Table</h5>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Actions</th>
                    <th scope="col">Code</th>
                    <th scope="col">%</th>
                    <th scope="col">Effected price</th>
                    <th scope="col">Active status</th>

                  </tr>
                </thead>
                <tbody>
               
                  {couponData.map((item, index) => (
                      <tr key={index}>
                         <td>
                         
                         <a 
                         onClick={()=>deleteCouponConfirmAlert(item)} 
                         style={{cursor:'pointer',color:'red'}}>
                         <i class="fa fa-trash" aria-hidden="true"/>
                         </a>
                         <a
                          style={{cursor:'pointer'}}
                          onClick={()=>editCouponCode(item)}
                          >
                         <i class="fa fa-edit" 
                         aria-hidden="true" 
                         style={{marginLeft:"10px"}}></i>

                         </a>

                         </td>
                        <td>
                         
                          {item.code}
                        
                         
                          </td>

                        <td>{item.discount_percentage}</td>
                        <td>{item.couponCodeDescountedPrice}</td>
                        <td>{item.status}</td>
                       
                      </tr>
                    ))}
                  
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </>
  );
}

export default CouponCode;