export default build =>
    build.query({
        tagTypes: ['allFreeVideos'],
        keepUnusedDataFor: 1,
        query(payload) {
            console.log("in get course all resources",payload)            
            return {
                url: `/course/getAllFreeYoutubeVideosByCourseId?course_id=${payload.course_id}`,
                method: 'GET',
                //body: payload,
            };
           
        },
       providesTags: ['allFreeVideos']
    })
