import React from 'react'

function Footer() {
  return (
    <>
     <footer id="footer" class="footer mt-auto">
    <div class="copyright">
      &copy; Copyright <strong><span>Skilluphere</span></strong>. All Rights Reserved
    </div>
    {/* <div class="credits">
    
      Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
    </div> */}
  </footer>
    </>
  )
}

export default Footer