import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'courseData',
    initialState: { course_data: null },
    reducers: {
        updateCourseData: (state, { payload: { data } }) => {
            state.course_data = data
        },
        clearCourseData: (state, { }) => {
            state.course_data = null
        },
    },
})

export const { updateCourseData, clearCourseData } = slice.actions

export default slice.reducer
