import React, { useEffect, useState } from 'react';
import { useCreateCouponCodeMutation, useCreateFreeVideoLinkMutation, useCreateResourceMutation, useDeleteCouponMutation, useDeleteFreeVideoLinkMutation, useDeleteResourceMutation, useEditCouponMutation, useGetAllFreeVideosQuery, useGetCouponCodesQuery, useGetResourcesQuery, useUpdateFreeVideoLinkMutation, useUpdateResourceMutation } from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WarningModal from './WarningModal';

function CourseFreeVideos({ course_details }) {
  console.log("coupon code course id", course_details.data[0]._id);
  const [freevideo, setFreeVideo] = useState({
    media_url_youtube: "",   
    course_id: course_details.data[0]._id  ,
    media_url_youtube_id:""
  });
  const[isSumbmiting,setIsSubmiting]=useState(false)
  const [isFormValid, setIsFormValid] = useState(false);
  const[freeVideosData,setFreeVideosData]=useState([])
  const[deleteModal,setDeleteModal]=useState(false)
  const[deleteFreeVideo,setDeleteFreeVideo]=useState(false)
  const[isEdit,setisEdit]=useState(false)
  const[media_url_youtube_id_for_delete,setFreeVideoIdForDelete]=useState("")
  const [CreateFreeVideoLink, 
    { data, isSuccess, isLoading, isError, error }] = 
    useCreateFreeVideoLinkMutation();

  useEffect(() => {
    setIsSubmiting(false)
    clearForm()
    setIsFormValid(false);

    if (isSuccess) {
      if (data.error === false) {
        toast.success(data.message);
      }
      if (data.error === true) {
        toast.error(data.message);
      }
    }
  }, [data]);
  const { 
    data: allFreeVideos= [],  
    isLoading:loading=true,
    isSuccess:isSuccessFreeVideos,
    isError:isErrorFreeVideos
   } = useGetAllFreeVideosQuery({course_id:course_details.data[0]._id});
 
   useEffect(() => {    
    if (isSuccessFreeVideos) {
        console.log("InSuccess",allFreeVideos);
      
        if(allFreeVideos.error==true)
        {
          toast.error(allFreeVideos.message)          
          return 
        }
        else
        {
          setFreeVideosData(allFreeVideos.data)
           
        }
     
    }
    if (isError) {
        alert("In error")
        console.log(error);
    }
}, [allFreeVideos]);
const [DeleteFreeVideoLink, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteLoading, isError:deleteError }] = useDeleteFreeVideoLinkMutation({course_id:course_details.data[0]._id,media_url_youtube_id:media_url_youtube_id_for_delete});
const [UpdateFreeVideoLink, { data:editData, isSuccess:editSuccess, isLoading:editLoading, isError:editError }] = useUpdateFreeVideoLinkMutation({course_id:course_details.data[0]._id,media_url_youtube_id:media_url_youtube_id_for_delete});
  useEffect(()=>{
    setFreeVideoIdForDelete("")
    setDeleteModal(false)
    setDeleteFreeVideo(false)
    if(deleteSuccess)
    {
      if(deleteData.error==false)
      {
        toast.success(deleteData.message)

      }
      if(deleteData.error==true)
      {
        toast.error(deleteData.message)

      }
    }
  },[deleteData])
  useEffect(()=>{
    setFreeVideoIdForDelete("")
    setisEdit(false)
    setIsSubmiting(false)
    clearForm()
    if(editSuccess)
    {
      if(editData.error==false)
      {
        toast.success(editData.message)

      }
      if(editData.error==true)
      {
        toast.error(editData.message)

      }
    }
  },[editData])
  useEffect(()=>{
    if(deleteFreeVideo)
    {
      DeleteFreeVideoLink({
        course_id:course_details.data[0]._id,
        media_url_youtube_id:media_url_youtube_id_for_delete
      })
    }
   },[deleteFreeVideo])
  
  const validateForm = () => {
    const { media_url_youtube } = freevideo;
    if (media_url_youtube !== "") {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setFreeVideo({
      ...freevideo,
      [e.target.name]: value,
    });
    validateForm();
  };

  const onSubmit = () => {
    setIsSubmiting(true)
    if(isEdit)
    {
      UpdateFreeVideoLink(freevideo)
    }
    else
    {
      CreateFreeVideoLink(freevideo);

    }
  };
  const clearForm=()=>{       
    setFreeVideo({
      ...freevideo,
      media_url_youtube:"",          
    })
  }
  const deleteFreeVideoConfirmAlert=(record)=>{   
    setDeleteModal(true)   
    setFreeVideoIdForDelete(record._id)  
   }
   const editFreeVideo=(record)=>{
    console.log("Edit record",record)
    setisEdit(true)
   // setCouponCode(record._id)
   setFreeVideo({
      ...freevideo,
      media_url_youtube:record.media_url_youtube,   
      media_url_youtube_id:record._id,
      course_id:course_details.data[0]._id,
      
    })
setIsFormValid(true)
   }
  
  return (
    <>
      <ToastContainer />
     {
      deleteModal?
      <WarningModal
      setShowWarningModal={setDeleteModal}
      modalTitle={"Delete Lesson"}
      setIsDelete={setDeleteFreeVideo}
      showModal={deleteModal}
      />:""
      
    }
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Create Free Video</h5>
            <div className="form-group">
              <label htmlFor="input1">Youtube Video Link</label>
              <input
                type="text"
                className="form-control mb-3"
                id="input1"
                name="media_url_youtube"
                value={freevideo.media_url_youtube}
                onChange={handleChange}
              />
            </div>
         
             <button
                  type="submit"
                  className="btn btn-primary btn-block mt-3"
                  onClick={() => onSubmit()}
                  disabled={ isLoading || isSumbmiting} 
                >
                  {isLoading || isSumbmiting ? 
                  'Submitting...' : 
                  isEdit?'Update':
                  'Submit'} 
                </button>
          </div>
        </div>
      </div>
      <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Table</h5>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Actions</th>
                    <th scope="col">Video</th>                  

                  </tr>
                </thead>
                <tbody>
               
                  {freeVideosData.map((item, index) => (
                      <tr key={index}>
                         <td>
                         
                         <a 
                         onClick={()=>deleteFreeVideoConfirmAlert(item)} 
                         style={{cursor:'pointer',color:'red'}}>
                         <i class="fa fa-trash" aria-hidden="true"/>
                         </a>
                         <a
                          style={{cursor:'pointer'}}
                          onClick={()=>editFreeVideo(item)}
                          >
                         <i class="fa fa-edit" 
                         aria-hidden="true" 
                         style={{marginLeft:"10px"}}></i>

                         </a>

                         </td>
                        <td>
                        <iframe
      width="100%"
      height="100%"
      src={`https://www.youtube.com/embed/${item.media_url_youtube}`}
      frameBorder="0"
      allowFullScreen
    />                      
                          </td>
                     
                      </tr>
                    ))}
                  
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </>
  );
}

export default CourseFreeVideos;