import { api } from '../../api'
import deleteUser from './deleteUser'

import getAllStudents from './getAllStudents'


export const reportApi = api.injectEndpoints({
    endpoints: build => ({
        GetAllStudents: getAllStudents(build),
        DeleteUser:deleteUser(build)
      
    }),
    overrideExisting: true,
})

export const { 
    useGetAllStudentsQuery,
    useDeleteUserMutation
   
 } = reportApi

