import React from 'react'
function PopModal({children,setShowModal,modalTitle}) {
    const handleCloseModal = () => {
        setShowModal(false);
      };
  return (
   <>
   <div className="modal" tabIndex="-1" role="dialog" 
   style={{ display: "block" }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" 
                data-dismiss="modal" aria-label="Close" 
                onClick={handleCloseModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              {children}
              </div>
            </div>
          </div>
        </div>
   </>
  )
}

export default PopModal